import { useRef, useEffect } from 'react'
import shopifyBuyButtonConfiguration from './shopifyBuyButtonConfiguration'

const SHOPIFY_DOMAIN = 'cpimhoff-design.myshopify.com'
const SHOPIFY_ACCESS_TOKEN = '6238ba8421e464c6b13d5f7ccf1285a5'
const SHOPIFY_JS_URL =
  'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'

function useShopifyBuyButton(id, isCollection) {
  const ref = useRef(null)

  useEffect(initEffect, [])

  function initEffect() {
    if (window.ShopifyBuy && window.ShopifyBuy.UI) {
      ShopifyBuyInit()
    } else {
      loadScriptThenInit()
    }
  }

  function loadScriptThenInit() {
    const script = document.createElement('script')
    script.async = true
    script.src = SHOPIFY_JS_URL
    const root =
      document.getElementsByTagName('head')[0] ||
      document.getElementsByTagName('body')[0]
    root.appendChild(script)
    script.onload = ShopifyBuyInit
  }

  function ShopifyBuyInit() {
    const client = window.ShopifyBuy.buildClient({
      domain: SHOPIFY_DOMAIN,
      storefrontAccessToken: SHOPIFY_ACCESS_TOKEN,
    })

    const componentType = isCollection ? 'collection' : 'product'

    window.ShopifyBuy.UI.onReady(client).then(ui => {
      ui.createComponent(componentType, {
        id,
        node: ref.current,
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: shopifyBuyButtonConfiguration,
      })
    })
  }

  return ref
}

export const useShopifyProductBuyButton = id => useShopifyBuyButton(id, false)
export const useShopifyCollectionBuyButton = id => useShopifyBuyButton(id, true)
