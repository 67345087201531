import React from 'react'

import Squiggle from './Squiggle'

const SquigglesAll = ({ colors }) => {
  const getColor = i => {
    return colors[i % colors.length]
  }

  return (
    <>
      <Squiggle
        type={1}
        color={getColor(0)}
        style={{
          top: '12em',
          left: '75%',
        }}
      />
      <Squiggle
        type={2}
        color={getColor(2)}
        style={{
          bottom: '20%',
          left: '-50pt',
        }}
      />
      <Squiggle
        type={3}
        color={getColor(2)}
        style={{
          bottom: '50%',
          left: 'calc(100vw - 40pt)',
        }}
      />
      <Squiggle
        type={4}
        color={getColor(1)}
        style={{
          top: '-5pt',
          left: 'calc(100vw - 120pt)',
        }}
      />
      <Squiggle
        type={6}
        color={getColor(0)}
        style={{
          top: '-20pt',
          left: '5%',
        }}
      />
    </>
  )
}

export default SquigglesAll
