import React from 'react'

/**
 * Dynamic data for the website.
 * This will need to be manually modified each rotation.
 *
 * Would be good to factor this out to a settings system
 * or to a data store but I'm not taking that step at this time.
 */

export const NOTEBOOKS_COLLECTION = {
  name: (
    <>
      Note
      <wbr />
      books
    </>
  ),
  description: (
    <>
      118 pages of 6x8 inch ruled line space to write love letters or ransom
      notes.
    </>
  ),
  slug: 'notebooks',
  commonPrice: 15,
  id: 210947571784,
}

export const BAGS_COLLECTION = {
  name: 'Bags',
  description: (
    <>
      18 inches tall & 18 inches wide.
      <br />
      Can fit... 8 pineapples? Maybe 9.
    </>
  ),
  slug: 'bags',
  commonPrice: 18,
  id: 210947539016,
}

export const CASES_COLLECTION = {
  name: 'Phone Cases',
  description: (
    <>
      Before these cases, I just had my phone between two slices of bread. These
      cases are sturdier and easier to slide in and out of your pocket.
      <br />
      <span style={{ color: 'lightgray' }}>Supports wireless charging.</span>
    </>
  ),
  slug: 'cases',
  commonPrice: 20,
  id: 210947637320,
}

export const COLLECTIONS = [
  NOTEBOOKS_COLLECTION,
  BAGS_COLLECTION,
  CASES_COLLECTION,
]
