import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import SquigglesAll from '../components/SquigglesAll'
import IndexBlurb from '../components/IndexBlurb'
import IndexNav from '../components/IndexNav'
import Collection from '../components/Collection'
import { COLLECTIONS } from '../catalog'
import TableOfContents from '../components/TableOfContents'

const IndexPage = () => {
  const collectionColors = ['#5601FF']

  return (
    <Layout>
      <SEO title="Home" />
      <IndexBlurb />
      <SquigglesAll colors={collectionColors} />
    </Layout>
  )
}

export default IndexPage
