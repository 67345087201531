import React from 'react'
import { Tag } from 'react-feather'
import Alert from './Alert'

import './IndexBlurb.css'

const IndexBlurb = () => {
  return (
    <div className="index-blurb">
      <p>
        <b>I used to sell stuff here.</b>
      </p>
      <p>
        I decided to take a break from selling. If you are interested in one of
        my prints, please feel free to reach out to me directly.
      </p>
    </div>
  )
}

export default IndexBlurb
